footer {
  padding: 15px 42px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

footer a {
  color: inherit;
  text-decoration: none;
}

footer a:hover {
  color: var(--accent);
  text-decoration: underline;
}

.top {
  display: flex;
  justify-content: space-between;
}

.socialLinks {
  display: flex;
  gap: 10px;
}

svg:hover {
  color: var(--accent);
  cursor: pointer;
}

.middle {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 30px 0 20px;
}
