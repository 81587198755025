.navbarContainer {
  display: flex;
  justify-content: space-between;
  min-height: 7vh;
  align-items: center;
  padding: 0 50px;
  position: fixed;
  top: 0;
  background-color: var(--background);
  width: 100%;
  box-sizing: border-box;
}

.navbarContainer a {
  text-decoration: none;
  color: inherit;
}

.navbarContainer a:hover {
  color: var(--accent);
  text-decoration: underline;
}

.menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 50px;
}

.menu ul li {
  display: inline;
}

.contact input {
  background-color: var(--background);
  border: solid 1px;
  border-radius: 5px;
  padding: 10px;
}

.contact a {
  background-color: var(--background);
  border: solid 1px;
  border-radius: 5px;
  padding: 10px;
  text-decoration: none;
  color: inherit;
  font-size: 13px;
}

.contact a:hover {
  background-color: var(--accent);
  border: solid 1px;
  border-radius: 5px;
  padding: 10px;
  text-decoration: none;
  color: white;
  font-size: 13px;
}

.menuButton {
  color: #1c1e53;
  background-color: transparent;
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid #1c1e53;
  cursor: pointer;
  transition: all 0.3s ease;
}

.menuButton:hover {
  background-color: var(--accent);
  border-color: var(--accent);
  color: #fff;
}

.menuButton {
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 7px;
}

.logo.open {
  display: none;
}

@media screen and (max-width: 992px) {
  .contact {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .menu ul {
    display: none;
  }

  .menuButton {
    display: flex;
  }

  ul.open {
    display: flex;
  }
}

@media screen and (max-width: 567px) {
  nav ul li a {
    font-size: 13px;
  }

  .navbarContainer {
    padding: 0 20px;
  }

  .menu ul {
    gap: 25px;
  }
}
