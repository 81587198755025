.homeContainer {
  color: var(--text);
  background-color: var(--secondaryBackground);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-self: center;
  /* height: 65%; */
  padding: 60px 20px 0px 20px;
  font-size: 34px;
  justify-content: space-between;
  /* max-height: calc(100vh - 66.5); */
  width: 100%;
}

.homeContainer img {
  max-width: 100%;
  max-height: 200px;
}

.homeContainer h1 {
  color: var(--accent);
  font-size: inherit;
  display: inline;
}

.description {
  font-size: 25px;
}

.homeContainer a {
  width: 100%;
  color: white;
  background-color: var(--accent);
  border-color: var(--accent);
  border-radius: 5px;
  font-size: 20px;
  margin-bottom: 20px;
  text-decoration: none;
  padding: 7px 15px;
  box-sizing: border-box;
  display: block;
  text-align: center;
}

.heading {
  font-size: 20px;
  padding-bottom: 20px;
}

.name {
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .homeContainer {
    max-height: 860px;
    min-height: 590px;
    flex-direction: row-reverse;
    height: 100%;
    justify-content: center;
    padding-top: 0;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
  }

  .homeContainer a {
    max-width: 150px;
  }

  .homeContainer img {
    max-height: none;
  }

  .name {
    padding-bottom: 20px;
  }
}
