.skillsContainer {
  display: flex;
  flex-direction: column;
  padding: 85px 42px 0;
}

.skillsIcons {
  display: flex;
  justify-content: space-between;
  align-self: center;
  flex-wrap: wrap;
  gap: 20px;
  box-sizing: border-box;
  margin-top: 70px;
}

.skillIcon {
  display: flex;
  flex-direction: column;
  background-color: var(--secondaryBackground);
  border-radius: 10px;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
}

.skillIcon span {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

span.bodyText {
  font-weight: normal;
}

.skillIcon {
  background-color: var(--secondaryBackground);
  width: 100%;
  border-radius: 10px;
  padding: 32px;
  border-bottom: 4px solid transparent;
}

.icon {
  color: var(--accent);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  margin-bottom: 22px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 5px;
}

.skillIcon:hover {
  border-color: var(--accent);
}

@media (min-width: 768px) {
  .skillIcon {
    width: 48%;
  }
}

@media (min-width: 1200px) {
  .skillIcon {
    width: 30%;
  }
}
