:root {
  --background: white;
  --secondaryBackground: #eff5f7;
  --accent: #4129a6;
  --text: black;
}

html,
body {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background);
  height: 100vh;
}

#root,
.App {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  h2 {
    font-size: 35px;
  }
}
