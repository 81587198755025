.portfolioContainer {
  background-color: var(--secondaryBackground);
  padding: 60px 42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.portfolioContainer h2 {
  margin-bottom: 60px;
}

.portfolioContainer a {
  color: inherit;
}

.portfolioContainer a:hover {
  color: var(--accent);
}

.projects {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 18px;
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.project {
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px 10px 20px 20px;
  width: 80%;
  border-bottom: 4px solid transparent;
}

.project:hover {
  border-color: var(--accent);
}

.projectImg {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 10px 10px 20px 20px;
}

.projectInfo {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 20px 32px 42px;
}

.projectTitle {
  font-weight: bold;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .project {
    width: 48%;
  }
}

@media screen and (min-width: 1200px) {
  .project {
    width: 30%;
  }
}
